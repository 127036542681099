import { VIDEOS_REQUEST, VIDEOS_SUCCESS, VIDEOS_FAIL } from "../actions/videos";

const initialState = {
    loading: false,
    videos: [],
};

export default function videos(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case VIDEOS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case VIDEOS_SUCCESS:
            return {
                ...state,
                loading: false,
                videos: payload,
            };
        case VIDEOS_FAIL:
            return {
                ...state,
                loading: false,
                videos: null,
            };
        default:
            return state;
    }
}
