import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import contact from "./contact";
import about from "./about";
import videos from "./videos";
import alerts from "./alerts";

const rootReducer = combineReducers({
    auth,
    user,
    contact,
    about,
    videos,
    alerts,
});

export default rootReducer;
