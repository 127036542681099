import axios from "axios";
import { headers } from "../shared/utils/conf";
import { openAlert } from "./alerts";

export const VIDEOS_REQUEST = "VIDEOS_REQUEST";
export const VIDEOS_SUCCESS = "VIDEOS_SUCCESS";
export const VIDEOS_FAIL = "VIDEOS_FAIL";

export const getVideos = () => async (dispatch) => {
    dispatch({
        type: VIDEOS_REQUEST,
    });

    try {
        const res = await axios.get("/api/videos", { headers });

        dispatch({
            type: VIDEOS_SUCCESS,
            payload: res.data.videos,
        });
    } catch (err) {
        dispatch(openAlert("error", "Server error"));
        dispatch({
            type: VIDEOS_FAIL,
        });
    }
};

export const putVideos = (videos) => async (dispatch) => {
    dispatch({
        type: VIDEOS_REQUEST,
    });

    try {
        const res = await axios.put("/api/videos", videos, { headers });

        dispatch(openAlert("success", "Successfully modified"));
        dispatch({
            type: VIDEOS_SUCCESS,
            payload: res.data.videos,
        });
    } catch (err) {
        dispatch(openAlert("error", "Server error"));
        dispatch({
            type: VIDEOS_FAIL,
        });
    }
};
