import { CONTACT_REQUEST, CONTACT_SUCCESS, CONTACT_FAIL } from "../actions/contact";

const initialState = {
    loading: false,
    contact: null,
};

export default function contact(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CONTACT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTACT_SUCCESS:
            return {
                ...state,
                loading: false,
                contact: payload,
            };
        case CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                contact: null,
            };
        default:
            return state;
    }
}
