import React, { memo } from "react";
import logoPurple from "../../../shared/assets/images/logo-white.png";
import styles from "./spinner.module.scss";

const Spinner = () => (
    <div className={styles.spinner}>
        <img src={logoPurple} alt="La Dulce Liberal" />
    </div>
);

export default memo(Spinner);
