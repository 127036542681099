import { ABOUT_REQUEST, ABOUT_SUCCESS, ABOUT_FAIL } from "../actions/about";

const initialState = {
    loading: false,
    about: null,
};

export default function about(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ABOUT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ABOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                about: payload,
            };
        case ABOUT_FAIL:
            return {
                ...state,
                loading: false,
                about: null,
            };
        default:
            return state;
    }
}
