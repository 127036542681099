import axios from "axios";
import { headers } from "../shared/utils/conf";
import { openAlert } from "./alerts";

export const CONTACT_REQUEST = "CONTACT_REQUEST";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_FAIL = "CONTACT_FAIL";

export const getContact = () => async (dispatch) => {
    dispatch({
        type: CONTACT_REQUEST,
    });

    try {
        const res = await axios.get("/api/contact", { headers });

        dispatch({
            type: CONTACT_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch(openAlert("error", "Server error"));
        dispatch({
            type: CONTACT_FAIL,
        });
    }
};

export const putContact = (contact) => async (dispatch) => {
    dispatch({
        type: CONTACT_REQUEST,
    });

    try {
        const res = await axios.put("/api/contact", contact, { headers });

        dispatch(openAlert("success", "Successfully modified"));
        dispatch({
            type: CONTACT_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch(openAlert("error", "Server error"));
        dispatch({
            type: CONTACT_FAIL,
        });
    }
};
