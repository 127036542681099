import axios from "axios";
import { headers } from "../shared/utils/conf";
import { openAlert } from "./alerts";

export const ABOUT_REQUEST = "ABOUT_REQUEST";
export const ABOUT_SUCCESS = "ABOUT_SUCCESS";
export const ABOUT_FAIL = "ABOUT_FAIL";

export const getAbout = () => async (dispatch) => {
    dispatch({
        type: ABOUT_REQUEST,
    });

    try {
        const res = await axios.get("/api/about", { headers });

        dispatch({
            type: ABOUT_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch(openAlert("error", "Server error"));
        dispatch({
            type: ABOUT_FAIL,
        });
    }
};

export const putAbout = (about) => async (dispatch) => {
    dispatch({
        type: ABOUT_REQUEST,
    });

    try {
        const res = await axios.put("/api/about", about, { headers });

        dispatch(openAlert("success", "Successfully modified"));
        dispatch({
            type: ABOUT_SUCCESS,
            payload: res.data,
        });
    } catch (err) {
        dispatch(openAlert("error", "Server error"));
        dispatch({
            type: ABOUT_FAIL,
        });
    }
};
